//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------
//

header.makeHeaderSticky = function() {
    var doc = $(document);

    $(document).on('scroll', function () {
        $('#header.header').toggleClass('header_scrolled', doc.scrollTop() > 0);
        $('.js-hook-headerSpacerDesktop').toggleClass('header__spacer_desktop_scrolled', doc.scrollTop() > 0);
    });
};
header.breadCrumbScroll = function () {
	$('#js-hook-crumb-container').html($("#js-hook-breadcrumb").clone());
	$('#js-hook-crumb-container').hide();
	
	$(window).scroll(function(){
		if(!$('#js-hook-crumb-inner').is(':hidden') && $('#js-hook-crumb-outer').position().top < $(window).scrollTop()){
			$('#js-hook-crumb-inner').hide();
			$('#js-hook-crumb-container').show();
		} else if ($('#js-hook-crumb-inner').is(':hidden') && $('#js-hook-crumb-outer').position().top >= $(window).scrollTop()) {
			$('#js-hook-crumb-inner').show();
			$('#js-hook-crumb-container').hide();
		}
	});
}